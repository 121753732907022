import React, { useState, useEffect } from "react";
import "./App.css";

function App() {
  const startDate = new Date("2024-12-09T12:00:00");
  const [timePassed, setTimePassed] = useState("");

  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      const diff = now - startDate;

      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((diff / (1000 * 60)) % 60);
      const seconds = Math.floor((diff / 1000) % 60);

      setTimePassed(`${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds`);
    };

    const interval = setInterval(updateTime, 1000);
    updateTime();

    return () => clearInterval(interval);
  // eslint-disable-next-line
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <p>Dear Dan,</p>
        <p>Seonaid has has kept this plant alive for</p>
        <h1>{timePassed}</h1>
        <p>and therefore deserves a dog.</p>
      </header>
    </div>
  );
}

export default App;
